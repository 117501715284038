import { Outbound } from "@mui/icons-material";

export const config = {
  baseUrl: "https://gateway-portico.intraversa.com/admin",
  basUrlVersion: "/api/v1",
  appUserName: "W0QP67kqrfDZ2b89nnht",
  appPassword: "0VXu1zAjx88MwTbAwm9Z",
  appSecret: "S7O00cbancx7oOfYOntcE1UBInRn72oY",
  appClientId: "portico-admin-api"
};
