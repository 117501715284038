import { useEffect, useMemo, useState } from 'react'

import isEqual from 'lodash/isEqual';
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField, useConfirmModal } from '@intraversa-lab/styleguide'
import {
  ButtonPortico,
  IconTrash,
  InputFieldRHF,
  InputSelect,
  InputText,
  Modal,
  TextFieldRHF,
} from 'components'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { resolve } from 'path'

import * as S from '../styles/styles'

import { ProfileSchema, profileSchema } from './profileSchema'
import { useAuth } from 'services'
import { useGetAffiliationByCompanyByUserId } from 'domains/Affiliation/useCases'
import { useDeleteUser, usePostChangePassword, usePostUserComplete, usePutUserComplete } from 'domains/User/useCases'
import { useGetRole } from 'domains/Role/useCase'
import { IRole } from 'domains/Role'
import { NewPasswordSchema } from 'modules/auth/ChangePassword/components/NewPassword/newPasswordSchema'
import { ModalChangePassword } from './modalChangePassword'
import { InputSelectdRHF } from 'components/FormRHF/InputSelectRHF'

interface IFormProfile {
  isMode: 'edit' | 'create'
}

type TOptions = {
  value: string,
  label: string
}

export const FormProfile = ({ isMode }: IFormProfile) => {
  const [resetKey, setResetKey] = useState(false)
  const [rolesOptions, setRolesOptions] = useState<TOptions[]>([] as TOptions[])

  const navigate = useNavigate()
  const { user } = useAuth()
  const { userId } = useParams()

  const methods = useForm<ProfileSchema>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      nomeCompleto: '',
      email: '',
      cargo: '',
      roles: null,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
    control
  } = methods

  const filiationFields = useWatch({
    control
  })

  const { renderModal, updateModalStateAndOpenModal } = useConfirmModal()

  const { affiliation, isLoading } = useGetAffiliationByCompanyByUserId({
    companyId: String(user?.companies[0].id)!,
    userId: userId,
    enabled: isMode === 'edit'
  })

  const { postUserComplete } = usePostUserComplete({
    onSuccess: () => navigate('/profile')
  })

  const { putUserComplete } = usePutUserComplete(userId, {
    onSuccess: () => navigate('/profile')
  })

  const { deleteUser } = useDeleteUser({
    onSuccess: () => navigate('/profile')
  });

  const { role } = useGetRole({
    page: 0,
    offset: 10,
  })

  const handleDelete = () => {
    updateModalStateAndOpenModal({
      type: 'error',
      title: 'Excluir perfil',
      description: 'Essa ação irá excluir o perfil e apagar todos os dados dessa conta. Deseja continuar?',
      maxWidth: 449,
      onConfirm: () => deleteUser(affiliation.affiliation.id)
    })
  };


  function parseOption(data: IRole[]) {
    const result = data.map((item) => ({
      value: item.id,
      label: item.description
    }))
    return result
  }

  useEffect(() => {
    if (watch) {
      console.log(watch())
    
    }
  }, [watch])


  function CallModalCreateProfile(data) {
    updateModalStateAndOpenModal({
      type: 'warning',
      title: 'Atenção',
      description: 'Você irá criar um novo perfil. Deseja continuar?',
      onConfirm: () => createProfile(data)
    })
  }

  function CallModalEditProfile(data) {
    updateModalStateAndOpenModal({
      type: 'warning',
      title: 'Atenção',
      description: 'Ao salvar as alterações você irá alterar dados do perfil deste usuário. Deseja continuar?',
      onConfirm: () => editProfile(data)
    })
  }

  function CallModalCancelProfile() {
    updateModalStateAndOpenModal({
      type: 'warning',
      title: 'Atenção',
      description: 'Você irá descartar as alterações realizadas. Deseja continuar?',
      onConfirm: () => navigate('/profile')
    })
  }

  function createProfile(data: ProfileSchema) {
    const payload = {
      email: data.email,
      name: data.nomeCompleto,
      companyPosition: data.cargo,
      companyId: String(user?.companies[0].id)!,
      roles: [data.roles]
    }
    postUserComplete(payload)
  }

  function editProfile(data: ProfileSchema) {
    const payload = {
      email: data.email,
      name: data.nomeCompleto,
      companyPosition: data.cargo,
      companyId: String(user?.companies[0].id)!,
      roles: [data.roles]
    }

    putUserComplete(payload)
  }


  const isChangedInitialValueWhenEditMode = useMemo(() => {
    if (affiliation) {
      const { name, email, companyPosition, roles } = affiliation || {};
      const fieldValuesValidationEquals = {
        nomeCompleto: name,
        email,
        cargo: companyPosition,
        roles: roles[0]?.roleId
      };
  
      return !isEqual(filiationFields, fieldValuesValidationEquals);
    }
    return false;
  }, [affiliation, filiationFields]);

  useEffect(() => {
    if (!isLoading && affiliation && !!rolesOptions.length) {
      setValue('nomeCompleto', affiliation.name);
      setValue('email', affiliation.email);
      setValue('cargo', affiliation.companyPosition);
      setValue('roles', String(affiliation.roles[0]?.roleId))

    }
  }, [affiliation, isLoading, rolesOptions]);


  useEffect(() => {
    if (role) {
      const result = parseOption(role)
      setRolesOptions(result)
    }
  }, [role, ])


  // console.log(watch())
  // console.log(errors)




  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">{isMode === 'edit' ? 'Detalhes' : 'Cadastrar Novo Perfil'}</h1>
          <p style={{marginTop:"16px"}}>{isMode === 'edit'
              ? 'Atualize e gerencie informações de cadastro aqui.'
              : 'Preencha os campos abaixo para cadastrar um novo perfil.'}</p>
        </S.WrapperHeader>
      </S.Container>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(isMode === 'edit' ? CallModalEditProfile : CallModalCreateProfile)}>
          <S.ContentDescription>
            <div className="wrapper">
              <div className="info">
                <span>Nome Completo</span>
                <span>
                  {isMode === 'edit'
                    ? 'Alterar nome e sobrenome'
                    : 'Adicione nome e sobrenome'}
                </span>
              </div>

              <div style={{ width: '100%' }}>
                <TextFieldRHF
                  placeholder="Nome Completo"
                  control={control}
                  name="nomeCompleto"
                />
              </div>
            </div>
          </S.ContentDescription>

          <S.ContentDescription>
            <div className="wrapper">
              <div className="info">
                <span>Email</span>
                <span>
                  {isMode === 'edit'
                    ? 'Alterar email da conta'
                    : 'Adicione email para criação de conta'}
                </span>
              </div>

              <div style={{ width: '100%' }}>
                <TextFieldRHF placeholder="Email" control={control} name="email" />
              </div>
            </div>
          </S.ContentDescription>

          <S.ContentDescription>
            <div className="wrapper">
              <div className="info">
                <span>Cargo</span>
                <span>
                  {isMode === 'edit'
                    ? 'Alterar o cargo na empresa'
                    : 'Adicione o cargo na empresa'}
                </span>
              </div>

              <div style={{ width: '100%' }}>
                <TextFieldRHF placeholder="Cargo" control={control} name="cargo" />
              </div>
            </div>
          </S.ContentDescription>

          <S.ContentDescription>
            <div className="wrapper">
              <div className="info">
                <span>Permissão</span>

                <span>
                  {isMode === 'edit'
                    ? 'Alterar o tipo de permissão desse usuário'
                    : 'Selecione o tipo de permissão do usuário'}
                </span>
              </div>

              <div style={{ width: '100%' }}>

                <InputSelectdRHF
                  placeholder={'Selecione a permissão'}
                  options={rolesOptions}
                  name='roles' 
                  isError={errors?.roles ? true: false}
                />
                <p className='text-error'>{errors?.roles && 'Campo Obrigatório'}</p>

              </div>
            </div>
          </S.ContentDescription>

          {isMode === 'edit' && (
            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Configurações da conta</span>
                  <span>Alterar senha ou excluir perfil</span>
                </div>
                <div className="content-button">
                  <ButtonPortico
                    type='button'
                    variant="outlined"
                    onClick={() => setResetKey(true)}>
                    Redefinir Senha
                  </ButtonPortico>

                  <ButtonPortico
                    type='button'
                    onClick={handleDelete}
                    variant="outlined" style={{ paddingLeft: '16px' }}
                  >
                    <IconTrash />
                    Excluir Perfil
                  </ButtonPortico>
                </div>
              </div>
            </S.ContentDescription>
          )}

          <S.ContentButtonForm>
            <ButtonPortico
              type='button'
              variant="outlined"
              onClick={isChangedInitialValueWhenEditMode ? CallModalCancelProfile : () => navigate('/profile')}
              style={{ maxWidth: '320px', width: '100%' }}>
              Cancelar
            </ButtonPortico>

            <ButtonPortico
              type='submit'
              style={{ maxWidth: '320px', width: '100%' }}>
              {
                isMode === 'edit' ? 'Salvar Alterações' : 'Cadastrar'
              }
            </ButtonPortico>
          </S.ContentButtonForm>
        </form>
      </FormProvider>

      {renderModal()}

      <ModalChangePassword
        isOpen={resetKey}
        onClose={() => setResetKey(false)}
        isCredendial={affiliation?.credential}
      />
    </>
  )
}
